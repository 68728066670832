<template>
  <div class="h-full flex flex-col">
    <div class="h-full flex-shrink min-h-0 flex">
      <div class="w-1/2 border-r-2 border-gray-light min-w-0">
        <DataColumn title="Metadata" :data="record.meta.plain" />
      </div>
      <div class="w-1/2 min-w-0">
        <DataColumn title="Data" :data="record.data" />
      </div>
    </div>
    <div
      v-if="!clientBackUpRecord"
      class="border-t-2 border-gray-light px-4 py-2 flex justify-end"
    >
      <Actions
        :actions="recordActions"
        @action="handleActions"
        auto-id="token-card"
      />
    </div>
  </div>
</template>

<script>
import DataColumn from '@/Common/DataColumn'
import Actions from '@/Common/Actions'
export default {
  name: 'RecordView',
  components: {
    DataColumn,
    Actions,
  },
  props: {
    record: Object,
  },
  data() {
    return {
      recordActions: [
        {
          event: 'edit',
          altText: 'Edit Record',
          icon: 'edit',
        },
        {
          event: 'delete',
          altText: 'Delete Record',
          icon: 'delete',
        },
      ],
    }
  },
  computed: {
    clientBackUpRecord: function () {
      return this.record.meta.type === 'tozny.key_backup'
    },
  },
  methods: {
    handleActions(action) {
      this.$emit(action.event)
    },
  },
}
</script>

<style></style>
