<template>
  <EditColumn
    submitText="Update Record"
    :title="`Edit ${recordId}`"
    :record="record"
    :error="singleError"
    :loading="singleStatus === 'updating'"
    @submit="handleSubmit"
    @cancel="
      $router.push(`/clients/${$route.params.clientId}/record/${recordId}`)
    "
  />
</template>

<script>
// @ is an alias to /src
import EditColumn from './Components/EditColumn'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'EditRecord',
  components: {
    EditColumn,
  },
  computed: {
    ...mapState('clients/client/records', [
      'singleStatus',
      'singleError',
      'recordId',
    ]),
    ...mapGetters('clients/client/records', ['record']),
  },
  methods: {
    ...mapActions('clients/client/records', ['updateRecord']),
    async handleSubmit(r) {
      await this.updateRecord(r)
      if (this.singleStatus !== 'error') {
        this.$router.push(
          `/clients/${this.$route.params.clientId}/record/${this.recordId}`
        )
      }
    },
  },
}
</script>
