<template>
  <RecordError
    v-if="singleStatus === 'error'"
    :message="singleError"
    @action="$router.push(`/clients/${clientId}`)"
    actionText="Go Back"
  />
  <div v-else class="h-full">
    <RecordView
      :record="record"
      @edit="$router.push(`${recordId}/edit`)"
      @delete="deleteRequest"
    />
    <RecordDeleteDialogue
      :showing="deleting"
      :record="record"
      @confirm="doDelete"
      @cancel="cancelDelete"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import RecordError from './Components/RecordError'
import RecordView from './Components/RecordView'
import RecordDeleteDialogue from './Components/RecordDeleteDialogue'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'ViewRecord',
  components: {
    RecordError,
    RecordView,
    RecordDeleteDialogue,
  },
  data() {
    return {
      deleting: false,
    }
  },
  computed: {
    ...mapState('clients/client/records', [
      'recordId',
      'singleError',
      'singleStatus',
    ]),
    ...mapGetters('clients/client/records', ['record']),
  },
  methods: {
    ...mapActions('clients/client/records', ['deleteRecord']),
    deleteRequest() {
      this.deleting = true
    },
    cancelDelete() {
      this.deleting = false
    },
    doDelete() {
      this.deleting = false
      this.deleteRecord(this.recordId)
      this.$router.push(`/clients/${this.$route.params.clientId}`)
    },
  },
}
</script>
