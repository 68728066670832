<template>
  <div class="flex flex-col h-full">
    <div
      class="p-4 pb-2 border-b-2 border-gray-light flex-grow-0 flex justify-between items-center"
    >
      <div class="text-gray-dark">
        <h3 class="inline-block mr-2 font-display1 text-base">{{ title }}</h3>
        <span class="text-tiny">{{ numberOfKeys }}</span>
      </div>
      <div class="flex">
        <a
          herf="#!"
          @click.prevent="toggleDisplay"
          :class="displayClass"
          class="flex cursor-pointer no-underline"
        >
          <span class="sr-only">Toggle list/code display</span>
          <i
            title="toggle list/code display"
            class="material-icons-outlined md-24 micon"
            >code</i
          >
        </a>
        <slot name="extras" />
      </div>
    </div>
    <div class="flex-shrink h-full p-4 overflow-x-scroll min-h-0">
      <DataViewer :data="data" :display="display" />
    </div>
  </div>
</template>

<script>
import DataViewer from '@/Common/DataViewer'
export default {
  name: 'DataColumn',
  components: {
    DataViewer,
  },
  props: {
    title: String,
    data: {
      type: Object,
      validator: (data) =>
        Object.keys(data).every(
          (key) => typeof key === 'string' && typeof data[key] === 'string'
        ),
    },
    initialDisplay: {
      type: String,
      default: 'list',
    },
  },
  data() {
    return {
      display: this.initialDisplay,
    }
  },
  computed: {
    displayClass() {
      return this.display === 'list' ? 'text-gray-dark' : 'text-gray-darkest'
    },
    numberOfKeys() {
      const num = Object.keys(this.data).length
      return num === 1 ? '1 Key' : `${num} Keys`
    },
  },
  methods: {
    toggleDisplay() {
      this.display = this.display === 'list' ? 'code' : 'list'
    },
  },
}
</script>

<style></style>
