<template>
  <div
    v-if="singleStatus === 'loading'"
    class="flex items-center justify-center h-full"
  >
    <ToznyLoader class="text-tozny w-8 h-8" />
  </div>
  <router-view v-else />
</template>

<script>
// @ is an alias to /src
import ToznyLoader from '@/Common/ToznyLoader'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'Record',
  components: {
    ToznyLoader,
  },
  computed: {
    ...mapState('clients/client', {
      clientStatus: 'status',
      clientId: 'clientId',
    }),
    ...mapState('clients/client/records', ['singleStatus', 'singleError']),
  },
  methods: {
    ...mapActions('clients/client/records', ['selectRecord']),
    ...mapMutations('clients/client/records', {
      clear: 'CLEAR_RECORD',
    }),
    async initialize() {
      if (this.clientStatus !== 'active') {
        this.clear()
        return
      }
      await this.selectRecord(this.$route.params.recordId)
    },
  },
  async created() {
    await this.initialize()
  },
  watch: {
    $route: 'initialize',
  },
}
</script>
